.coins {
    background-size: 100% 100%;
    border-radius: 100%;
    height: 100px;
    position: relative;
    width: 100px;
}

.coins.coin-1st {
    background-image: url("./assets/imgs/first-place.png");
}

.coins.coin-2nd {
    background-image: url("./assets/imgs/second-place.png");
}

.coins.coin-3rd {
    background-image: url("./assets/imgs/third-place.png");
}

.coins.happy-face {
    background-image: url("./assets/imgs/happy_face.gif");
}

.coins.medium-face {
    background-image: url("./assets/imgs/medium_face.gif");
}

.coins.sad-face {
    background-image: url("./assets/imgs/sad_face.gif");
}

.coins.serious-face {
    background-image: url("./assets/imgs/serious-face.gif");
}

.person-card {
    background-color: white;
}

.person-card.shiny.gold {
    background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #56421a 78%);
}

.person-card.shiny.silver {
    background: linear-gradient(to bottom, #c8c8c8 27%, #a3a3a3 40%, #3e3e3e 78%);
}

.person-card.shiny.bronze {
    background: linear-gradient(to bottom, #b2a588 27%, #968c6f 40%, #31240b 78%);
}

.person-card.shiny.gold:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 1s infinite;
    animation-delay: 2s;

    /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* IE10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
}

/* animation */

@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.marquee-wrapper{
    text-align:center;
  }
  .marquee-wrapper .container{
    overflow:hidden;
    text-align:center;
  }
  .marquee-wrapper .marquee-block{
    width: 100%;
    height: calc(100vh - 145px);
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    float:left;
  }
  .marquee-inner{
    display: block;
    height: 100%;
    width:100%;
    position: absolute;
  }
  
  .marquee-inner.to-left{
    animation: marqueeTop 5s linear infinite;
  }
  .marquee-inner.to-right{
    animation: marqueeBottom 5s linear infinite;
  }
  @keyframes marqueeTop{
    0% {
      top: 0;
    }
    100% {
      top: -100%;
    }
  }
  @keyframes marqueeBottom{
    0% { 
      top: -100%; 
    }
    100% {
     top: 0; 
    }
  }
